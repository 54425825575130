import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const Container = styled.section``

const Image = ({ data }) => {
  if (!data || !data.localFile) return null

  return (
    <Container>
      <Img
        fluid={data.localFile.childImageSharp.fluid}
        objectFit="cover"
        style={{
          width: `100%`,
          height: `100%`,
          display: `block`,
        }}
        alt=""
        role="presentation"
      />
    </Container>
  )
}

Image.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Image
