import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Link from "../link"

const Container = styled.section``

const Article = styled.article`
  figure {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    margin-bottom: 3em;
    display: block;
    position: relative;

    @media ${(props) => props.theme.largeDown} {
      margin-bottom: 2em;
    }

    @media ${(props) => props.theme.smallDown} {
      margin-bottom: 1em;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  p {
    margin-bottom: 1.5em;
    opacity: 0.7;
  }
`

const Meta = styled.footer.attrs({ className: `styled-p` })`
  margin-top: 1em;
  display: flex;
  color: ${(props) => props.theme.colorPurpleLight};

  time {
    white-space: nowrap;
    position: relative;
    padding-right: 1.4em;

    &::after {
      content: "";
      width: 0.4em;
      height: 0.4em;
      position: absolute;
      top: 0.45em;
      right: 0.5em;
      background-color: currentColor;
      border-radius: 50%;
      opacity: 0.5;
    }
  }

  ul {
    li {
      display: inline;

      &:not(:last-child) {
        &::after {
          content: ", ";
        }
      }
    }
  }
`

const AboutBlurbs = ({ data, showTitle }) => {
  const { title, blurbs } = data.acf

  if (!blurbs || !blurbs.length) return null

  return (
    <Container>
      <Grid>
        {title && (
          <Row className={!showTitle && `is-vhidden`}>
            <Cell
              size={5}
              mq={{ mediumDown: { size: 9 }, xsmallDown: { size: 20 } }}
            >
              <h2 className="styled-h3 styled">{title}</h2>
            </Cell>
          </Row>
        )}

        <Row pushY={1} pullY={2} mq={{ xsmallDown: { pullY: 6 } }}>
          {blurbs.map(({ image, title, text, link, date, type }, i) => (
            <Cell
              key={i}
              size={7}
              pushX={i % 3 !== 0 && 1}
              pushY={i >= 3 && 2}
              mq={{
                mediumDown: {
                  size: 11,
                  pushX: i % 2 !== 0 && 1,
                  pushY: i >= 2 && 2,
                },
                xsmallDown: {
                  size: 23,
                  pushX: i % 1 !== 0 && 1,
                  pushY: i >= 1 && 4,
                },
              }}
            >
              <Article>
                {link && link.url && image && image.localFile && (
                  <figure>
                    <Link to={link.url} target={link.target || undefined}>
                      <Img
                        fluid={image.localFile.childImageSharp.fluid}
                        alt={image.alt_text}
                        objectPosition="center center"
                        objectFit="cover"
                      />
                    </Link>
                  </figure>
                )}

                <h3 className="styled-p-leading">{title}</h3>

                <p className="styled-p-leading">{text}</p>

                {link && link.url && link.title && (
                  <div className="styled-p-leading">
                    <Link
                      to={link.url}
                      target={link.target || undefined}
                      className="styled-a"
                    >
                      {link.title}
                    </Link>
                  </div>
                )}

                {type && (
                  <Meta>
                    {date && <time dateTime={date}>{date}</time>}
                    <ul>
                      <li>{type}</li>
                    </ul>
                  </Meta>
                )}
              </Article>
            </Cell>
          ))}
        </Row>
      </Grid>
    </Container>
  )
}

AboutBlurbs.propTypes = {
  data: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
}

export default AboutBlurbs
