import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Cell from "../dotgrid/cell"
import TestimonialsQuote from "../testimonials/quote"

const Container = styled.section``

const Quote = ({ data }) => {
  if (!data.text || !data.text.length) return null

  return (
    <Container>
      <Grid>
        <Cell size={11} pullY={1} mq={{ xsmallDown: { size: 23, pullY: 2 } }}>
          <TestimonialsQuote data={data} />
        </Cell>
      </Grid>
    </Container>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Quote
